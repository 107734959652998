<template>
  <v-container
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      overflow-y: scroll;
      margin-top: 2%;
    "
    fluid
  >
    <Loading v-if="loading" />
    <div
      v-else-if="!assessmentStarted"
      style="
        display: flex;
        justify-content: center;
        margin-top: 10px;
        width: 100%;
        height: 100vh;
      "
    >
      <div
        style="
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          text-align: center;
          width: 50%;
        "
      >
        Welcome to the Assessment.<br />
        As you go through each section, you will be asked to select a level of
        competency for each skill. Your progress will save with each response so
        that you can return to the assessment at any time.
        <b
          >Feel free to provide a comment for each skill to give context as to
          why you selected the response you did.</b
        >
        <br />
        Click below to begin
        <v-btn
          style="margin-top: 20px"
          color="secondary"
          @click="beginAssessment"
          >Start</v-btn
        >
      </div>
    </div>
    <v-row v-else style="display: flex">
      <v-col cols="auto" style="margin-left: 5%">
        <!-- <div style="display: flex; flex-direction: column; margin-top: 20px">
          <div style="display: flex; flex-grow: 1; justify-content: center">
            {{ assessmentUserName }}'s Assessment
          </div>
        </div> -->
        <div class="categories">
          <div
            v-for="(category, categoryIndex) in assessment.categories"
            :key="category.id"
            style="max-width: 400px"
          >
            <b
              class="category-name"
              style="font-size: 20px; font-weight: bold; margin-bottom: 10px"
              >{{ category.name }}</b
            >
            <div
              v-for="(subCategory, subCategoryIndex) in category.subCategories"
              :key="subCategoryIndex"
              :style="`color: ${
                categoryIndex === currentCategoryIndex &&
                subCategoryIndex === currentSubCategoryIndex
                  ? '#275dad'
                  : assessment.categories[categoryIndex].subCategories[
                      subCategoryIndex
                    ].skills.every((skill) => skill.selectedSkillLevel !== null)
                  ? 'rgba(0, 171, 102)'
                  : '#5b616a;'
              }`"
              style="max-width: 400px; cursor: pointer; display: flex"
              @click="() => goToSelectedSkill(categoryIndex, subCategoryIndex)"
              class="category-name"
            >
              {{ subCategory.name }}
              <div
                style="display: flex; flex-direction: row"
                v-if="
                  categoryIndex === currentCategoryIndex &&
                  subCategoryIndex === currentSubCategoryIndex
                "
              >
                <div
                  v-for="(skill, index) in assessment.categories[
                    currentCategoryIndex
                  ].subCategories[currentSubCategoryIndex].skills"
                  :key="index"
                >
                  <v-icon
                    v-if="
                      !skill.selectedSkillLevel &&
                      skill.selectedSkillLevel !== 0
                    "
                    style="color: #656565; margin-left: 5px; font-size: 14px"
                    >mdi-checkbox-blank-circle-outline</v-icon
                  >
                  <v-icon
                    v-else
                    style="
                      color: rgba(0, 171, 102);
                      margin-left: 5px;
                      font-size: 14px;
                    "
                    >mdi-checkbox-blank-circle</v-icon
                  >
                </div>
              </div>
            </div>
          </div>
          <v-btn
            v-if="assessmentComplete"
            style="width: 170px; margin-top: 20px"
            color="#275dad"
            elevation="2"
            outlined
            @click="finishEditingAssessment"
          >
            Submit Assessment
          </v-btn>
        </div>
      </v-col>
      <div
        v-if="!assessmentStarted"
        style="
          display: flex;
          margin-top: 10px;
          flex-direction: column;
          width: 100%;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin-left: 150px;
        "
      >
        Welcome to the Assessment.<br />
        As you go through each section, you will be asked to select a level of
        competency for each skill. Your progress will save with each response so
        that you can return to the assessment at any time. You can also provide
        a comment for each skill to give context as to why you selected the
        response you did. You will also be able to see how each question is
        weighted. This reflects how impactful this portion of the assessment is
        to your overall score. <br />
        Click below to begin
        <button @click="beginAssessment">Start</button>
      </div>
      <v-col
        class="d-flex justify-center"
        style="style: display: flex; display: flex; justify-content: center; flex-direction: column; align-items: center;"
        v-else-if="!showAssessmentSubmitView"
      >
        <div style="display: flex; justify-content: center; margin-top: 15px">
          Weight:&nbsp;<b>{{
            assessment.categories[currentCategoryIndex].subCategories[
              currentSubCategoryIndex
            ].skills[currentSkillIndex].weight
          }}</b>
        </div>
        <div
          style="
            display: flex;
            justify-content: space-around;
            flex-direction: column-reverse;
            flex-grow: 1;
            margin-top: 15px;
            margin-left: -12%;
          "
        >
          <div
            v-for="(skillLevel, index) in assessment.categories[
              currentCategoryIndex
            ].subCategories[currentSubCategoryIndex].skills[currentSkillIndex]
              .skillLevels"
            :key="index"
            style="
              display: flex;
              justify-content: flex-start;
              border-radius: 10px;
            "
            :style="`
              ${!skillLevel ? 'display: none;' : ''}`"
          >
            <div style="width: 150px; display: flex; align-items: center">
              <v-icon
                v-if="currentSkill.selectedSkillLevel === index"
                style="font-size: 20px; margin-left: -20px"
                >mdi-chevron-right</v-icon
              >
              <div
                :style="`${
                  currentSkill.selectedSkillLevel === index
                    ? 'font-weight: bold;'
                    : ''
                }`"
              >
                {{
                  skillLevel && assessment.levels[index - 1]
                    ? assessment.levels[index - 1].title
                    : ""
                }}
              </div>
            </div>
            <div
              :style="`
              display: ${
                !skillLevel ? 'none' : 'flex'
              }; background: ${backgroundColor(index)}; ${
                currentSkill.selectedSkillLevel === index
                  ? 'border: 3px solid #275dad;'
                  : ''
              } `"
              @click="selectSkillLevel(index)"
              style="
                padding: 5px 10px;
                border-radius: 5px;
                cursor: pointer;
                min-width: 650px;
                max-width: 650px;
                font-size: 16px;
                border-radius: 5px;
                box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
                flex-direction: column;
              "
            >
              <div
                style="font-weight: bold"
                v-if="
                  skillLevel &&
                  assessment.levels[index - 1] &&
                  assessment.levels[index - 1].heading
                "
              >
                {{ assessment.levels[index - 1].heading }} <br />
              </div>
              {{ skillLevel ? skillLevel.label : "" }}
            </div>
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <v-btn
                style="margin-left: 20px"
                color="secondary"
                v-if="currentSkill.selectedSkillLevel === index"
                @click="toggleCommentModal()"
                >{{
                  currentSkill.comment ? "Edit Comment" : "Add Comment"
                }}</v-btn
              >
            </div>
          </div>
        </div>
        <div style="width: 100%; display: flex; justify-content: center">
          <div>
            <v-btn
              style="width: 120px; margin: 20px"
              color="#275dad"
              elevation="2"
              outlined
              :disabled="
                currentSkillIndex === 0 &&
                currentSubCategoryIndex === 0 &&
                currentCategoryIndex === 0
              "
              @click="previousSkill()"
              >Previous</v-btn
            >
          </div>
          <div>
            <v-btn
              style="width: 120px; margin: 20px"
              color="#275dad"
              elevation="2"
              outlined
              @click="() => nextSkill()"
              :disabled="selectedSkillIndex === null"
            >
              Continue
            </v-btn>
          </div>
        </div>
      </v-col>
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 200px;
          text-align: center;
        "
        v-else
      >
        <div style="margin: 25px">
          Nice job! You're all set. <br />
          Click below to submit your assessment.
        </div>
        <div style="width: 100%; display: flex; justify-content: center">
          <v-btn
            color="secondary"
            style="width: 200px; margin-bottom: 25px"
            @click="submitAssessment"
          >
            Submit
          </v-btn>
        </div>
        <p style="color: red">
          NOTE* Once an assessment has been submitted you will no longer be able
          to make changes.
        </p>
        <p></p>
      </div>
    </v-row>
    <div style="display: none">{{ assessment }}</div>
    <v-dialog v-model="showCommentModal" width="500">
      <v-card>
        <v-card-title class="grey lighten-2"> Add a comment </v-card-title>

        <div style="padding: 30px">
          <v-textarea
            outlined
            label="Use as many words as you'd like!"
            v-model="comment"
          ></v-textarea>
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            :disabled="!comment || (comment && comment.length === 0)"
            text
            @click="addComment()"
          >
            Add Comment
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { doc, updateDoc, addDoc, collection  } from "firebase/firestore";

import { db } from "../main.js";
import {
  getExistingAssessmentDetails,
  getAssessmentTemplate,
  getAssessmentById,
  setAssessmentDateStarted,
  setAssessmentDateCompleted,
  createAssessmentDetails,
} from "../../api/endpoints/assessment";

import { getUserData } from "../../api/endpoints/user";
import Loading from "./Loading.vue";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      assessment: null,
      assessmentId: null,
      assessmentStarted: false,
      currentCategoryIndex: null,
      currentSubCategoryIndex: null,
      currentSkillIndex: null,
      selectedSkillIndex: null,
      comment: null,
      showAssessmentSubmitView: false,
      currentDocumentId: null,
      loading: true,
      showCommentModal: false,
      assessmentRequest: null,
      assessmentUserName: null,
      firstTimeCompletingAssessment: false,
      assessorUserName: null
    };
  },
  computed: {
    currentSkill() {
      if (this.currentCategoryIndex !== null) {
        return this.assessment.categories[this.currentCategoryIndex]
          .subCategories[this.currentSubCategoryIndex].skills[
          this.currentSkillIndex
        ];
      } else {
        return null;
      }
    },
    currentComment() {
      if (this.currentCategoryIndex !== null) {
        return this.assessment.categories[this.currentCategoryIndex]
          .subCategories[this.currentSubCategoryIndex].skills[
          this.currentSkillIndex
        ].comment;
      } else {
        return null;
      }
    },
    assessmentComplete() {
      return this.assessment.categories.every((category) => {
        return category.subCategories.every((subCategory) => {
          return subCategory.skills.every((skill) => {
            return skill.selectedSkillLevel !== null;
          });
        });
      });
    },
    showPreviousButton() {
      return (
        this.currentSkillIndex === 0 &&
        this.currentSubCategoryIndex === 0 &&
        this.currentCategoryIndex === 0
      );
    },
  },
  methods: {
    async init() {
      if (!this.$route.query.assessmentId) {
        this.$router.push("/dashboard");
      } else {
        this.assessmentId = this.$route.query.assessmentId;
        await this.loadAssessment();
      }

      this.loading = false;
    },
    async beginAssessment() {
      this.assessmentStarted = true;
      this.currentCategoryIndex = 0;
      this.currentSubCategoryIndex = 0;
      this.currentSkillIndex = 0;

      if (this.assessmentRequest.dateStarted === null) {
        await setAssessmentDateStarted(this.assessmentRequest.id);
      }
    },
    selectSkillLevel(selection) {
      this.currentSkill.selectedSkillLevel = selection;
      this.currentSkill.comment = this.comment;
      this.selectedSkillIndex = selection;
    },
    async nextSkill() {
      // need to calculate if we need to move to the next skill, subcategory, or category.
      if (
        this.assessmentComplete &&
        !this.showAssessmentSubmitView &&
        !this.firstTimeCompletingAssessment
      ) {
        this.firstTimeCompletingAssessment = true;
        this.showAssessmentSubmitView = true;
        return;
      }
      if (
        this.currentSkillIndex ===
        this.assessment.categories[this.currentCategoryIndex].subCategories[
          this.currentSubCategoryIndex
        ].skills.length -
          1
      ) {
        if (
          this.currentSubCategoryIndex ===
          this.assessment.categories[this.currentCategoryIndex].subCategories
            .length -
            1
        ) {
          this.currentCategoryIndex++;
          this.currentSubCategoryIndex = 0;
          this.currentSkillIndex = 0;
        } else {
          this.currentSubCategoryIndex++;
          this.currentSkillIndex = 0;
        }
      } else {
        this.currentSkillIndex++;
      }

      // this is for when the user ends up at a skill question they've already answered.
      // We need to get their current selection and comment from the saved assessment
      this.populateSavedAssessmentAnswer();
      await this.saveAssessmentProgress();
    },
    previousSkill() {
      if (
        this.currentSkillIndex === 0 &&
        this.currentSubCategoryIndex === 0 &&
        this.currentCategoryIndex === 0
      ) {
        return;
      }
      // need to calculate if we need to move to the next skill, subcategory, or category.
      if (this.currentSkillIndex === 0) {
        if (this.currentSubCategoryIndex === 0) {
          this.currentCategoryIndex--;
          this.currentSubCategoryIndex =
            this.assessment.categories[this.currentCategoryIndex].subCategories
              .length - 1;
          this.currentSkillIndex =
            this.assessment.categories[this.currentCategoryIndex].subCategories[
              this.currentSubCategoryIndex
            ].skills.length - 1;
        } else {
          this.currentSubCategoryIndex--;
          this.currentSkillIndex =
            this.assessment.categories[this.currentCategoryIndex].subCategories[
              this.currentSubCategoryIndex
            ].skills.length - 1;
        }
      } else {
        this.currentSkillIndex--;
      }

      this.selectedSkillIndex = this.currentSkillindex;

      // this is for when the user ends up at a skill question they've already answered.
      // We need to get their current selection and comment from the saved assessment
      this.populateSavedAssessmentAnswer();
    },
    async submitAssessment() {
      this.assessment.dateCompleted = new Date().toISOString();
      setAssessmentDateCompleted(this.assessmentRequest.id);
      await this.saveAssessmentProgress();
      await this.sendCompletionEmail();

      this.$router.push({
        name: "dashboard",
        query: { userId: this.assessmentRequest.assessorId },
      });
    },
    finishEditingAssessment() {
      // this.nextSkill()
      this.showAssessmentSubmitView = true;
    },
    populateSavedAssessmentAnswer() {
      if (
        this.assessment.categories[this.currentCategoryIndex].subCategories[
          this.currentSubCategoryIndex
        ].skills[this.currentSkillIndex].selectedSkillLevel !== null
      ) {
        this.selectedSkillIndex = this.currentSkill.selectedSkillLevel;
        this.comment = this.currentSkill.comment;
      } else {
        this.selectedSkillIndex = null;
        this.comment = null;
      }
    },
    async saveAssessmentProgress() {
      try {
        await updateDoc(
          doc(db, "assessmentDetails", this.currentDocumentId),
          this.assessment
        );
      } catch (error) {
        console.log("error", error);
      }
    },
    async loadAssessment() {
      const existingAssessment = await getExistingAssessmentDetails(
        this.assessmentId
      );
      this.assessmentRequest = await getAssessmentById(this.assessmentId);

      const assessmentUser = await getUserData(
        this.assessmentRequest.assessedId
      );
      const assessingUser = await getUserData(
        this.assessmentRequest.assessorId
      );

      this.assessorUserName = assessingUser.name
      this.assessmentUserName = assessmentUser.name;

      if (existingAssessment) {
        this.assessment = existingAssessment;
        this.currentDocumentId = existingAssessment.id;
        await this.beginAssessment();
        this.setFirstUnansweredSkill();
      } else {
        this.assessment = await getAssessmentTemplate(
          this.assessmentRequest.templateId
        );
        this.assessment.assessmentId = this.assessmentRequest.id;
        delete this.assessment["name"];
        this.currentDocumentId = await createAssessmentDetails(this.assessment);
      }
    },
    setFirstUnansweredSkill() {
      for (
        let categoryIndex = 0;
        categoryIndex < this.assessment.categories.length;
        categoryIndex++
      ) {
        for (
          let subCategoryIndex = 0;
          subCategoryIndex <
          this.assessment.categories[categoryIndex].subCategories.length;
          subCategoryIndex++
        ) {
          for (
            let skillIndex = 0;
            skillIndex <
            this.assessment.categories[categoryIndex].subCategories[
              subCategoryIndex
            ].skills.length;
            skillIndex++
          ) {
            if (
              this.assessment.categories[categoryIndex].subCategories[
                subCategoryIndex
              ].skills[skillIndex].selectedSkillLevel === null
            ) {
              this.currentCategoryIndex = categoryIndex;
              this.currentSubCategoryIndex = subCategoryIndex;
              this.currentSkillIndex = skillIndex;
              return;
            }
          }
        }
      }
    },
    goToSelectedSkill(categoryIndex, subCategoryIndex) {
      if (this.showAssessmentSubmitView) {
        this.showAssessmentSubmitView = false;
      }
      if (subCategoryIndex === undefined) {
        subCategoryIndex = 0;
      }

      if (
        this.assessment.categories[categoryIndex].subCategories[
          subCategoryIndex
        ].skills.some((skill) => skill.selectedSkillLevel === null)
      ) {
        return;
      }
      this.currentCategoryIndex = categoryIndex;
      this.currentSubCategoryIndex = subCategoryIndex;
      this.currentSkillIndex = 0;
      this.selectedSkillIndex = 0;
    },
    backgroundColor(index) {
      if (index === 0) {
        return `rgba(128, 128, 128, 0.2);`;
      }
      index = index / 7;
      index = index.toFixed(2);

      if (index >= 10) {
        return "rgba(0, 171, 102, 1)";
      } else {
        return `rgba(0, 171, 102, ${index})`;
      }
    },
    toggleCommentModal() {
      this.showCommentModal = !this.showCommentModal;
    },
    addComment() {
      this.currentSkill.comment = this.comment;
      this.toggleCommentModal();
    },
    async sendCompletionEmail() {
      await addDoc(collection(db, "mail"), {
        to: 'mnicolls@technologypartners.net, khicks@technologypartners.net, cmellor@technologypartners.net ',
        message: {
          subject: "A developer has completed an assessment",
          html: `<p>Hello you,</p>` +
            `<p>${this.assessorUserName} has completed an assessment for ${this.assessmentUserName}.</p>` +
            `<p>Seems like something you might want to know.</p>` +
            `<p>Love,</p>` +
            `<p>Chris</p>`
        }
      })

    }
  },

  async created() {
    await this.init();
  },
  watch: {
    currentComment(val) {
      this.comment = val;
    },
  },
};
</script>
<style scoped>
.categories {
  border-right: 2px solid white;
  line-height: 2em;
  display: inline-block;
  -webkit-box-shadow: 15px 0px 10px -10px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 15px 0px 10px -10px rgba(0, 0, 0, 0.25);
  box-shadow: 15px 0px 10px -10px rgba(0, 0, 0, 0.25);
  margin-right: 50px;
  padding-right: 60px;
  margin-top: 20px;
}
.skill-option {
  height: 120px;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #d3d3d3;
  margin: 10px;
  border-radius: 10px;
  text-align: center;
  overflow: scroll;
  font-size: 13px;
  width: 250px;
}
.skill-option-comment {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #328dd1;
  margin: 10px;
  border-radius: 10px;
  text-align: center;
  overflow: scroll;
  font-size: 13px;
  width: 250px;
}
.skill-option:hover {
  border: 3px solid rgba(0, 171, 102);
  cursor: pointer;
}

.form-control {
  border: none !important;
  transition: none;
}
.form-control::after {
  border: none;
}
.text-area {
  border: none;
  resize: none;
  width: 200px;
  height: 100px;
}
.text-area:focus {
  border: 0 none #fff;
  overflow: hidden;
  outline: none;
  overflow: scroll;
}

.category-name {
  color: #5f5f5f;
}

::-webkit-input-placeholder {
  font-weight: 700;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-weight: 700;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-weight: 700;
}

:-ms-input-placeholder {
  font-weight: 700;
}
</style>
